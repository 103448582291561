import { Link } from "gatsby";
import React from "react";

import PageWrapper from "../components/PageWrapper";

const Terms = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <Link
                to="/signup"
                className="btn btn btn-blue-3 header-btn-2 font-size-3 rounded-5"
              >
                Start for Free
              </Link>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11 mb-8">Privacy Policy</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <p className="mt-4 mb-10 small font-italic">
              Last updated February 02, 2023
            </p>
            <div className="mt-4 mb-10">
              This privacy notice for MODWISE LLC (doing business as Modwise)
              ("Modwise," "we," "us," or "our"), describes how and why we might
              collect, store, use, and/or share ("process") your information
              when you use our services ("Services"), such as when you: Visit
              our website at https://modwise.ai/privacy, or any website of ours
              that links to this privacy notice Engage with us in other related
              ways, including any sales, marketing, or events Questions or
              concerns? Reading this privacy notice will help you understand
              your privacy rights and choices. If you do not agree with our
              policies and practices, please do not use our Services. If you
              still have any questions or concerns, please contact us at
              support@modwise.ai.
            </div>
            <h5>1. WHAT INFORMATION DO WE COLLECT?</h5>
            <div className="mt-4 mb-10">
              <span className="font-weight-bold">
                What personal information do we process?
              </span>{" "}
              When you visit, use, or navigate our Services, we may process
              personal information depending on how you interact with Modwise
              and the Services, the choices you make, and the products and
              features you use.
            </div>
            <h5>Personal information you disclose to us</h5>
            <div className="mt-4 mb-10 font-italic">
              In Short: We collect personal information that you provide to us.
            </div>
            <div className="mt-4 mb-10">
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </div>
            <div className="mt-4 mb-10">
              <span className="font-weight-bold">
                Personal Information Provided by You.
              </span>{" "}
              The personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make,
              and the products and features you use. The personal information we
              collect may include the following:
            </div>
            <ul>
              <li>names</li>
              <li>email addresses</li>
              <li>phone numbers</li>
              <li>job information</li>
              <li>usernames</li>
              <li>passwords</li>
              <li>contact preferences contact or authentication data</li>
              <li>employer data</li>
            </ul>
            <div className="mt-4 mb-10">
              <span className="font-weight-bold">Payment Data.</span> We use
              stripe to handle any form of payment. Stripe may collect data
              necessary to process your payment if you make purchases, such as
              your payment instrument number, and the security code associated
              with your payment instrument. All payment data is stored by
              Stripe. You may find their privacy notice link(s) here:{" "}
              <a href="https://stripe.com/privacy" target="_blank">
                https://stripe.com/privacy
              </a>
              .
            </div>
            <h5>Information automatically collected</h5>
            <div className="mt-4 mb-10 font-italic">
              In Short: Some information — such as your Internet Protocol (IP)
              address and/or browser and device characteristics — is collected
              automatically when you visit our Services.
            </div>
            <div className="mt-4 mb-10">
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </div>
            <div className="mt-4 mb-10">
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </div>
            <div className="mt-4 mb-10">
              The information we collect includes:
              <ul>
                <li>
                  <i>Log and Usage Data.</i> Log and usage data is
                  service-related, diagnostic, usage, and performance
                  information our servers automatically collect when you access
                  or use our Services and which we record in log files.
                  Depending on how you interact with us, this log data may
                  include your IP address, device information, browser type, and
                  settings and information about your activity in the Services
                  (such as the date/time stamps associated with your usage,
                  pages and files viewed, searches, and other actions you take
                  such as which features you use), device event information
                  (such as system activity, error reports (sometimes called
                  "crash dumps"), and hardware settings).
                </li>
                <li>
                  <i>Device Data.</i> We collect device data such as information
                  about your computer, phone, tablet, or other device you use to
                  access the Services. Depending on the device used, this device
                  data may include information such as your IP address (or proxy
                  server), device and application identification numbers,
                  location, browser type, hardware model, Internet service
                  provider and/or mobile carrier, operating system, and system
                  configuration information.
                </li>
                <li>
                  <i>Location Data.</i> We collect location data such as
                  information about your device's location, which can be either
                  precise or imprecise. How much information we collect depends
                  on the type and settings of the device you use to access the
                  Services. For example, we may use GPS and other technologies
                  to collect geolocation data that tells us your current
                  location (based on your IP address). You can opt out of
                  allowing us to collect this information either by refusing
                  access to the information or by disabling your Location
                  setting on your device. However, if you choose to opt out, you
                  may not be able to use certain aspects of the Services.
                </li>
                <li>
                  <i>Integration and plugins' metadata.</i> Any metadata from
                  plugins and integrations including but not limited to Discord,
                  Slack, Telegram and Signal
                </li>
              </ul>
            </div>
            <h5>Information collected through our APIs</h5>
            <div className="mt-4 mb-10">
              We do not purposefully store messages or any user
              generated content relayed by third party applications integrated
              by you, such as Discord and Slack. We only use this type of
              content to calculate scores of different content categories such
              as sex, hate, violence, threat etc. We do store these numerical
              scores and use them to moderate your discord or slack channels or
              servers authorized to Modwise.
            </div>
            <h5>2. HOW DO WE PROCESS YOUR INFORMATION?</h5>
            <div className="mt-4 mb-10 font-italic">
              In Short: We process your information to provide, improve, and
              administer our Services, communicate with you, for security and
              fraud prevention, and to comply with law. We may also process your
              information for other purposes with your consent.
            </div>
            <div className="mt-4 mb-10">
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
              <ul>
                <li>
                  To facilitate account creation and authentication and
                  otherwise manage user accounts. We may process your
                  information so you can create and log in to your account, as
                  well as keep your account in working order.
                </li>
                <li>
                  To respond to user inquiries/offer support to users. We may
                  process your information to respond to your inquiries and
                  solve any potential issues you might have with the requested
                  service.
                </li>
                <li>
                  To send administrative information to you. We may process your
                  information to send you details about our products and
                  services, changes to our terms and policies, and other similar
                  information.
                </li>
                <li>
                  To request feedback. We may process your information when
                  necessary to request feedback and to contact you about your
                  use of our Services.
                </li>
                <li>
                  To send you marketing and promotional communications. We may
                  process the personal information you send to us for our
                  marketing purposes, if this is in accordance with your
                  marketing preferences. You can opt out of our marketing emails
                  at any time.
                </li>
                <li>
                  To deliver targeted advertising to you. We may process your
                  information to develop and display personalized content and
                  advertising tailored to your interests, location, and more.
                </li>
                <li>
                  To protect our Services. We may process your information as
                  part of our efforts to keep our Services safe and secure,
                  including fraud monitoring and prevention.
                </li>
                <li>
                  To identify usage trends. We may process information about how
                  you use our Services to better understand how they are being
                  used so we can improve them.
                </li>
                <li>
                  To determine the effectiveness of our marketing and
                  promotional campaigns. We may process your information to
                  better understand how to provide marketing and promotional
                  campaigns that are most relevant to you.
                </li>
                <li>
                  To save or protect an individual's vital interest. We may
                  process your information when necessary to save or protect an
                  individual’s vital interest, such as to prevent harm.
                </li>
              </ul>
            </div>
            <h5>3. HOW LONG DO WE KEEP YOUR INFORMATION?</h5>

            <div className="mt-4 mb-10 font-italic">
              In Short: We keep your information for as long as necessary to
              fulfill the purposes outlined in this privacy notice unless
              otherwise required by law.
            </div>

            <div className="mt-4 mb-10">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than three (3) months past the termination of the user's
              account.
            </div>

            <div className="mt-4 mb-10">
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </div>
            <h5>4. DO WE MAKE UPDATES TO THIS NOTICE?</h5>

            <div className="mt-4 mb-10 font-italic">
              In Short: Yes, we will update this notice as necessary to stay
              compliant with relevant laws.
            </div>

            <div className="mt-4 mb-10">
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </div>

            <h5>5. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h5>

            <div className="mt-4 mb-2">
              If you have questions or comments about this notice, you may email
              us at support@modwise.ai or by post to:
            </div>
            <div>MODWISE LLC</div>
            <div>P.O. Box #571</div>
            <div>Nassau, DE 19969</div>
            <div className="mb-10">United States</div>

            <h5>
              6. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h5>

            <div className="mt-4 mb-10">
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it. To request to
              review, update, or delete your personal information, please
              contact: support@modwise.ai
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Terms;
